<template>
  <section id="ubah-data-kegiatan">
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Simpan"
      back-button-text="Sebelumnya"
      class="vertical-steps steps-transparent mb-3"
      @on-complete="submitForm"
      @on-change="stepCheck"
    >

      <!-- Data Utama tab -->
      <!-- :before-change="validateDataUtama" -->
      <tab-content
        title="Data Utama"
        :before-change="validateDataUtama"
        :lazy="true"
      >
        <validation-observer ref="formDataUtama">
          <b-form>
            <ubah-data-utama-baru
              :core-data="coreData"
              :action-step="stepDataUtama"
              @step-completed="stepCompleted"
            />
          </b-form>
        </validation-observer>
      </tab-content>

      <!-- Peta Rencana tab -->
      <!-- :before-change="validatePetaRencana" -->
      <tab-content
        title="Peta Rencana"
        :before-change="validatePetaRencana"
        :lazy="true"
      >
        <validation-observer ref="formPetaRencana">
          <b-form>
            <ubah-peta-rencana
              :core-data="coreData"
              :action-step="stepPetaRencana"
              @step-completed="stepCompleted"
            />
          </b-form>
        </validation-observer>
      </tab-content>

      <!-- Aplikasi -->
      <!-- :before-change="validateAplikasi" -->
      <tab-content
        title="Aplikasi"
        :before-change="validateAplikasi"
        :lazy="true"
      >
        <validation-observer ref="formAplikasi">
          <b-form>
            <ubah-aplikasi-single
              :core-data="coreData"
              :sia-guid="siaGuid"
              :sia-kegiatan-guid="siaKegGuid"
              :sia-map-data="siaMap"
              :action-step="stepAplikasi"
              @step-completed="stepCompleted"
            />
          </b-form>
        </validation-observer>
      </tab-content>

      <!-- Layanan -->
      <!-- :before-change="validateLayanan" -->
      <tab-content
        title="Layanan"
        :before-change="validateLayanan"
        :lazy="true"
      >
        <validation-observer ref="formLayanan">
          <b-form>
            <ubah-layanan-baru
              :core-data="coreData"
              :clearance-id="data_utama_id"
              :action-step="stepLayanan"
              @step-completed="stepCompleted"
            />
          </b-form>
        </validation-observer>
      </tab-content>

      <!-- Proses Bisnis tab -->
      <!-- :before-change="validateBisnis" -->
      <tab-content
        title="Proses Bisnis"
        :before-change="validateBisnis"
        :lazy="true"
      >
        <validation-observer ref="formBisnis">
          <b-form>
            <ubah-bisnis-baru
              :core-data="coreData"
              :action-step="stepBisnis"
              :sakti="sakti"
              @step-completed="stepCompleted"
            />
          </b-form>
        </validation-observer>
      </tab-content>

      <!-- Data & Informasi -->
      <!-- :before-change="validateDataInformasi" -->
      <tab-content
        title="Data &amp; Informasi"
        :before-change="validateDataInformasi"
        :lazy="true"
      >
        <validation-observer ref="formInformasi">
          <b-form>
            <ubah-data-informasi-baru
              :core-data="coreData"
              :action-step="stepInformasi"
              @step-completed="stepCompleted"
            />
          </b-form>
        </validation-observer>
      </tab-content>

      <!-- Infrastruktur -->
      <!-- :before-change="validateInfra" -->
      <tab-content
        title="Infrastruktur"
        :before-change="validateInfra"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Referensi Infrastruktur
            </h5>
            <small class="text-muted">Arsitektur Domain Infrastruktur SPBE untuk seluruh Infrastruktur yang terkait dengan kegiatan.</small>
          </b-col>
        </b-row>
        <div
          v-for="(item, index) in totalInfra"
          :id="`infra-${item.id}`"
          :key="item.id"
          ref="row"
          :title="`Data Referensi Infrastruktur : ${item.nama}`"
        >
          <div class="border-bottom mb-0 card-text p-0 d-flex">
            <b-button
              v-b-toggle
              :href="`#collapse-infra-${item.id}`"
              variant="flat-secondary"
              class="text-left flex-grow-1"
              @click.prevent
            >
              {{ item.nama }}
              <b-badge
                v-if="item.is_verified_asdep || item.is_verified_bapenas"
                :variant="(item.is_verified_asdep === 1 || item.is_verified_bapenas === 1) ? 'light-success' : 'light-warning'"
              >
                {{ (item.is_verified_asdep === 1 || item.is_verified_bapenas === 1) ? 'Terverifikasi' : 'Perlu Perbaikan' }}
              </b-badge>
              <b-badge
                :id="`error-inf-${item.id}`"
                variant="light-danger"
                class="hidden"
              >
                Error Found
              </b-badge>
            </b-button>

            <b-button
              variant="flat-danger"
              @click="removeInfra(index, item)"
            >
              <feather-icon
                icon="XIcon"
              />
            </b-button>
          </div>
          <b-collapse
            :id="`collapse-infra-${item.id}`"
            class="mb-1"
          >
            <ubah-infrastruktur-referensi
              :core-data="coreData"
              :reference-data="infRefData"
              :app-data="item"
              :action-step="stepInfra"
              @step-completed="stepCompleted"
            />
          </b-collapse>
        </div>

        <div
          v-for="(item, index) in totalInfraBaru"
          :id="`infra-new-${item.id}`"
          :key="item.id"
          ref="row"
          :title="`Data Referensi Infrastuktur ${item.id}`"
        >
          <div class="border-bottom mb-0 card-text p-0 d-flex">
            <b-button
              v-b-toggle
              :href="`#collapse-infra-new-${item.id}`"
              variant="flat-secondary"
              class="text-left flex-grow-1"
              @click.prevent
            >
              Data Referensi Infrastuktur Baru {{ item.id }}
              <b-badge
                :id="`error-inf-new-${item.id}`"
                variant="light-danger"
                class="hidden"
              >
                Error Found
              </b-badge>
            </b-button>
            <b-button
              variant="flat-danger"
              @click="removeInfraBaru(index)"
            >
              <feather-icon
                icon="XIcon"
              />
            </b-button>
          </div>
          <b-collapse
            :id="`collapse-infra-new-${item.id}`"
            class="mb-1"
            visible
          >
            <tambah-infrastruktur-referensi
              :key="`inf_${item.id}`"
              :core-data="coreData"
              :app-data="item"
              :clearance-id="data_utama_id"
              :action-step="stepInfra"
              :reference-data="infRefData"
              @step-completed="stepCompleted"
            />
          </b-collapse>
        </div>

        <b-row>
          <b-col
            md="6"
            class="mb-1"
          >
            <b-button
              variant="success"
              class="mt-0 mt-md-2"
              @click="repeateInfra"
            >
              <span>Tambah Referensi Infrastruktur Baru</span>
            </b-button>
          </b-col>
        </b-row>
      </tab-content>

      <!-- Keamanan -->
      <!-- :before-change="validateKeamanan" -->
      <tab-content
        title="Keamanan"
        :before-change="validateKeamanan"
        :lazy="true"
      >
        <validation-observer ref="formKeamanan">
          <b-form>
            <ubah-keamanan
              :core-data="coreData"
              :action-step="stepKeamanan"
              @step-completed="stepCompleted"
            />
          </b-form>
        </validation-observer>
      </tab-content>

      <div v-if="errorMsg">
        <b-alert
          variant="danger"
          show
        >
          <h4 class="alert-heading">
            Error Found
          </h4>
          <div class="alert-body">
            <ul v-if="typeof errorMsg === 'object'">
              <li
                v-for="(item, index) in errorMsg"
                :key="index"
              >
                {{ index }}:
                <span
                  v-for="(child, indexs) in item"
                  :key="indexs"
                >
                  {{ child }}
                </span>
              </li>
            </ul>
            <span v-else>{{ errorMsg }}</span>
          </div>
        </b-alert>
      </div>
    </form-wizard>

    <b-modal
      id="modal-warning"
      ref="modal-warning"
      cancel-variant="outline-secondary"
      ok-variant="warning"
      cancel-title="Batal"
      ok-title="Ya, Hapus!"
      modal-class="modal-warning"
      centered
      title="Attention!"
      @cancel="clearDeleteItem()"
      @ok="deleteItem()"
    >
      <b-card-text>
        {{ alertText }}
      </b-card-text>
    </b-modal>
  </section>
</template>

<script>
import store from '@/store'
import { ValidationObserver } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BBadge, BForm, BCollapse, BAlert, BButton, BRow, BCol, VBTooltip, VBToggle, VBModal, BModal, BCardText,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import UbahDataUtamaBaru from '../ubah-kegiatan/UbahDataUtamaKrisna.vue'
import UbahAplikasiSingle from '../ubah-kegiatan/UbahAplikasiSingle.vue'
import UbahLayananBaru from '../ubah-kegiatan/UbahLayananBaru.vue'
import UbahBisnisBaru from '../ubah-kegiatan/UbahBisnisBaru.vue'
import UbahDataInformasiBaru from '../ubah-kegiatan/UbahDataInformasiBaru.vue'
import UbahInfrastrukturReferensi from '../ubah-kegiatan/UbahInfrastrukturReferensi.vue'
import UbahKeamanan from '../ubah-kegiatan/UbahKeamanan.vue'
import UbahPetaRencana from '../ubah-kegiatan/UbahPetaRencana.vue'
import TambahInfrastrukturReferensi from '../tambah-kegiatan/TambahInfrastrukturReferensi.vue'

export default {
  components: {
    ValidationObserver,
    FormWizard,
    TabContent,
    BCardText,
    BBadge,
    BAlert,
    BModal,
    BForm,
    BRow,
    BCol,
    BButton,
    BCollapse,

    UbahDataUtamaBaru,
    UbahAplikasiSingle,
    UbahLayananBaru,
    UbahBisnisBaru,
    UbahDataInformasiBaru,
    UbahInfrastrukturReferensi,
    UbahKeamanan,
    UbahPetaRencana,
    TambahInfrastrukturReferensi,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  props: {
    coreData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      userData: getUserData(),
      clearanceData: {},
      stepChange: 0,
      stepDataUtama: 0,
      stepPetaRencana: 0,
      stepBisnis: 0,
      stepLayanan: 0,
      stepInformasi: 0,
      stepAplikasi: 0,
      stepInfra: 0,
      stepKeamanan: 0,
      letter_id: '',
      jenis_pengadaan_id: 0,
      data_utama_id: 0,
      alertText: '',
      errorMsg: '',
      form: {},
      message: '',
      totalAplikasi: [],
      totalInfra: [],
      jumlahInfra: 0,
      totalInfraBaru: [],
      jumlahInfraBaru: 0,
      infRefData: {},
      sakti: {},
      siaGuid: '',
      siaKegGuid: '',
      siaMap: {},
    }
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role < 99 || role > 110) {
      next('/')
    } else {
      next()
    }
    if (!to.params.id) {
      next('/pengajuan/surat')
    } else {
      next()
    }
  },
  mounted() {
    localStorage.removeItem('guidUsed')
    this.data_utama_id = Number(this.$route.params.id)
    // check clearance data
    this.$http.get('/clearance/preview', {
      params: {
        token: localStorage.getItem('userToken'),
        data_utama_id: this.data_utama_id,
      },
    })
      .then(res => {
        if (res.data.status === 'success') {
          this.clearanceData = res.data.data
          this.show = true

          // if (this.clearanceData.program.status !== 'Draft' && this.clearanceData.program.status !== 'Draft Lengkap' && this.clearanceData.program.status !== 'Perlu Perbaikan') {
          //   this.$router.replace('/pengajuan')
          // }

          this.totalInfra = res.data.data.infra
          this.jumlahInfra = this.totalInfra.length
          this.getInfReference()
        }
      })
  },
  methods: {
    getInfReference() {
      this.$http.get('/clearance/infrastruktur/references', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.data_utama_id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.infRefData = res.data.data
          }
        })
    },
    clearDeleteItem() {
      this.toDelPath = ''
      this.toDelData = ''
      this.toDelItem = ''
    },
    deleteItem() {
      const params = {
        data_utama_id: this.data_utama_id,
      }
      if (this.toDelPath === 'infrastruktur') {
        params.infrastruktur_id = this.toDelData
      }
      this.$http.post(`/clearance/${this.toDelPath}/delete?token=${localStorage.getItem('userToken')}`, params)
        .then(res => {
          if (res.data.status === 'success') {
            if (this.toDelPath === 'infrastruktur') {
              this.totalInfra.splice(this.toDelItem, 1)
              this.jumlahInfra -= 1
            }
            this.clearDeleteItem()
          }
        })
        .catch(error => {
          this.errorMsg = error.response.data.error
        })
    },
    repeateInfra() {
      const jumlah = this.jumlahInfraBaru + 1
      this.totalInfraBaru.push({
        id: jumlah,
        ubah: true,
      })
      this.jumlahInfraBaru = jumlah
    },
    removeInfra(data, target) {
      this.alertText = `Apakah anda yakin untuk menghapus Referensi Infrastruktur ${target.nama}?`
      this.toDelPath = 'infrastruktur'
      this.toDelData = target.id
      this.toDelItem = data
      this.$refs['modal-warning'].show()
    },
    removeInfraBaru(data) {
      this.totalInfraBaru.splice(data, 1)
      this.jumlahInfraBaru -= 1
    },
    validateDataUtama() {
      this.$refs.formDataUtama.validate()
        .then(this.stepDataUtama += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.errorMsg = ''
          }
          resolve(value.status)
        })
      })
    },
    validatePetaRencana() {
      this.$refs.formPetaRencana.validate()
        .then(this.stepPetaRencana += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.data_utama_id = value.msg.data_utama_id
            this.errorMsg = ''
          }
          resolve(value.status)
        })
      })
    },
    validateBisnis() {
      this.$refs.formBisnis.validate()
        .then(this.stepBisnis += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.errorMsg = ''
          }
          resolve(value.status)
        })
      })
    },
    validateLayanan() {
      this.$refs.formLayanan.validate()
        .then(this.stepLayanan += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.errorMsg = ''
          }
          resolve(value.status)
        })
      })
    },
    validateDataInformasi() {
      this.$refs.formInformasi.validate()
        .then(this.stepInformasi += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.errorMsg = ''
          }
          resolve(value.status)
        })
      })
    },
    validateAplikasi() {
      this.$refs.formAplikasi.validate()
        .then(this.stepAplikasi += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.errorMsg = ''
          }
          resolve(value.status)
        })
      })
    },
    validateInfra() {
      const infValidated = []
      if (this.jumlahInfra !== 0 || this.jumlahInfraBaru !== 0) {
        return new Promise(resolve => {
          this.stepInfra += 1
          this.$on('step-validated', value => {
            if (!value.status) {
              this.errorMsg = value.msg
              infValidated.push(value.id)
              if (value.type === 'inf') {
                document.getElementById(`error-inf-${value.index}`).classList.remove('hidden')
              }
            } else {
              if (value.id && value.type === 'inf') {
                const index = (value.index) ? value.index : value.id
                document.getElementById(`error-inf-${index}`).classList.add('hidden')
              }
              this.errorMsg = ''
            }
            if (infValidated.length !== 0) {
              resolve(false)
            } else {
              this.errorMsg = ''
              resolve(true)
            }
          })
        })
      }
      this.errorMsg = 'Referensi Infrastruktur Tidak Boleh Kosong'
      return false
    },
    validateKeamanan() {
      this.$refs.formKeamanan.validate()
        .then(this.stepKeamanan += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.errorMsg = ''
          }
          resolve(value.status)
        })
      })
    },
    submitForm() {
      this.$router.replace({ name: 'kegiatan-detail', params: { id: this.data_utama_id } })
    },
    stepCheck(e) {
      this.stepChange = e
      this.errorMsg = ''
    },
    stepCompleted(data) {
      this.sakti = store.state.app.sakti
      this.siaGuid = store.state.app.siaGuid
      this.siaKegGuid = store.state.app.siaKegiatanGuid
      this.siaMap = store.state.app.siaMap
      this.$emit('step-validated', data)
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
